import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CustomPreloadingService implements PreloadingStrategy {

  constructor(
    private _authService: AuthService,
  ) {
    this.islogin = this._authService.loggedIn();
  }

  islogin: boolean = false;

  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preload'] && this.islogin) {
      return fn();
    }
    else {
      return of(null);
    }
  }
}
