import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';
import { AuthService } from '../services/authentication/auth.service';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from '../services/userService/user.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isButtonDisabled = false;
  // private timer: any;
  public showPassword: boolean;
  registerForm: FormGroup;
  forgetPassForm: FormGroup;
  submitted = false;
  loginBox: boolean = true;
  forgotPasswordBox: boolean = false;
  submittedForm = false;
  LoginWithOtpbox: boolean = false;
  LoginOTPForm: FormGroup;
  submittedFormOTP = false;
  otp: any;
  display: any;
  time: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private AuthService: AuthService,
    private router: Router,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private UserService: UserService,
    private titleService: Title
    ) { 
      this.titleService.setTitle('Login | Mekar');
    }

  emailPattern = "^[a-zA-Z0-9]([a-zA-Z0-9._-]*[a-zA-Z0-9])?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  ngOnInit(): void {

this.abcd();
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ['', [Validators.required]],
    });

    this.forgetPassForm = this.formBuilder.group({
      email_id: ['', [Validators.required, Validators.email]],
    });

    this.LoginOTPForm = this.formBuilder.group({
      email_id_OTP: [this.registerForm.controls.email.value, [Validators.required]],
      LOgin_OTP: ['']
    });
  }
  get f() { return this.registerForm.controls; }
  get p() { return this.forgetPassForm.controls; }
  get m() { return this.forgetPassForm.controls; }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    var data = {
      UserName: this.registerForm.value.email,
      Password: this.registerForm.value.password
    }
    if (this.registerForm.valid) {
      this.spinner.show();
      this.AuthService.login(data).subscribe(
        res => {

          this.spinner.hide();
          if (res['result'] == 'success') {
            this.disableButtonFor60Seconds()
            // console.log("abc",res['Token']);
            this.toastrService.success("Successfully Login")
            localStorage.clear();
            localStorage.setItem('AccessToken', res['accesstoken']);
            var encryptuserid = this.UserService.encryptData(res['Data']['UserID']);
            localStorage.setItem('UserId', encryptuserid);

            var permission = res['ModulePermission'];
            var comppermission = res['OperationPermission'];
            var encryptper = this.UserService.encryptData(comppermission);
            localStorage.setItem("Permission", JSON.stringify(encryptper));
            var encryptid = this.UserService.encryptData(permission);
            localStorage.setItem("ModulePermission", JSON.stringify(encryptid));
            
            var LoginRole = res['Data']['RoleID'];
            var Role = this.UserService.encryptData(LoginRole);
            localStorage.setItem("LoginRole", JSON.stringify(Role));

            var TerritoryID = res['Data']['TerritoryID'];
            var Territory = this.UserService.encryptData(TerritoryID);
            localStorage.setItem("TerritoryID", JSON.stringify(Territory));
            this.router.navigate(['/profile']);
          } else if (res['result'] == 'failed') {
            // console.log("fail");
            this.toastrService.error(res['Msg']);
          } else {
            //  console.log("fail2");
            this.toastrService.error("res['Message']");
          }
        })
    }
  }

  submitForgetPassForm() {
    this.submittedForm = true
    // stop here if form is invalid
    if (this.forgetPassForm.invalid) {
      return;
    }
    // console.log(this.forgetPassForm.value)
    var data = {
      "Email": this.forgetPassForm.value.email_id
    }
    this.spinner.show();
    this.UserService.ForgotPassword(data).subscribe(res => {
      this.spinner.hide();
      if (res['result'] == 'success') {
        this.toastrService.success("Kindly verify your registered email ID; we have sent you the password. If you haven't received it, please retry")
      } else if (res['result'] == 'failed') {
        this.toastrService.error(res['Msg']);
      } else {
        this.toastrService.error(res['Msg']);
      }
    })
  }

  onSubmitOTP() {
    this.time = true;
    // console.log(this.LoginOTPForm);
    this.submittedFormOTP = true
    // stop here if form is invalid
    if (this.LoginOTPForm.invalid) {
      return;
    }

    var data = {
      "EmailPhone": this.LoginOTPForm.value.email_id_OTP
    }
    this.spinner.show();
    this.AuthService.SendOTP(data).subscribe(res => {
      this.spinner.hide();


      this.spinner.hide();
      if (res['result'] == 'success') {
        this.timer(1);
        this.disableButtonFor60Seconds();
        //  console.log("abc",res['Token']);
        // this.otp = res['Data']['OTP'];
        localStorage.clear();
        //  console.log(res['accesstoken']);
        localStorage.setItem('AccessToken', res['accesstoken']);
        // var encryptuserid = this.UserService.encryptData(res['Data']['UserID']);
        // localStorage.setItem('UserId', encryptuserid);

      } else if (res['result'] == 'Failed') {
        //  console.log("fail");
        this.toastrService.error(res['Msg']);
        this.login();
      } else {
        //  console.log("fail2");
        this.toastrService.error("res['Msg']");
      }

    })
  }

  Verify() {
    this.submittedFormOTP = true
    if (this.LoginOTPForm.invalid) {
      return; 
    }
    //  console.log(this.LoginOTPForm.value)
    var data = {
      "EmailId":this.LoginOTPForm.value.email_id_OTP,
      "OTP": this.LoginOTPForm.value.LOgin_OTP
    }
    this.spinner.show();
    this.UserService.Verify(data).subscribe(res => {
      this.spinner.hide();

      if (res['result'] == 'success') {
        //  console.log("abc bharat mata ki jai");
        this.toastrService.success("Successfully Login")
            localStorage.clear();
            localStorage.setItem('AccessToken', res['accesstoken']);
            var permission = res['ModulePermission'];
            var comppermission = res['OperationPermission'];
            var encryptper = this.UserService.encryptData(comppermission);
            localStorage.setItem("Permission", JSON.stringify(encryptper));
            var encryptid = this.UserService.encryptData(permission);
            localStorage.setItem("ModulePermission", JSON.stringify(encryptid));
            var encryptuserid = this.UserService.encryptData(res['Data']['UserID']);
            localStorage.setItem('UserId', encryptuserid);
            var LoginRole = res['Data']['RoleID'];
            var Role = this.UserService.encryptData(LoginRole);
            localStorage.setItem("LoginRole", JSON.stringify(Role));
            var TerritoryID = res['Data']['TerritoryID'];
            var Territory = this.UserService.encryptData(TerritoryID);
            localStorage.setItem("TerritoryID", JSON.stringify(Territory));
            this.router.navigate(['/profile']);

      } else {
        //  console.log("fail2");
        this.toastrService.error(res['Msg']);
      }

    })
  }
  //Forgot Password
  ForgotPassword() {
    this.forgotPasswordBox = true;
    this.loginBox = false;
    this.LoginWithOtpbox = false;
  }
  LoginWithOTP() {
    this.LoginOTPForm = this.formBuilder.group({
      email_id_OTP: [this.registerForm.controls.email.value, [Validators.required]],
      LOgin_OTP: ['']
    });
    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var ab = emailRegex.test(this.registerForm.controls.email.value);
    // console.log(ab);
    if (this.registerForm.controls.email.value == '' || ab == false) {
      this.toastrService.error("Please enter Email");
      return;
    }
    // console.log(this.LoginOTPForm,this.registerForm.controls.email.value)
    this.LoginWithOtpbox = true;
    this.forgotPasswordBox = false;
    this.loginBox = false;
    this.onSubmitOTP();
  }

  //Login
  login() {
    this.forgotPasswordBox = false;
    this.loginBox = true;
    this.LoginWithOtpbox = false;
  }
  disableButtonFor60Seconds() {
    this.isButtonDisabled = true;

    // this.timer = setTimeout(() => {
    //   this.isButtonDisabled = false;
    // }, 60000);
  }

  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.time = false;
        console.log("finished");
        clearInterval(timer);
      }
    }, 1000);
  }
  abcd() {
   
    this.UserService.abcd().subscribe((res) => {
      if (res['result'] == 'success') {
      
      }
      else if (res['result'] == 'Failed') {
      
      } else {
        
      }
    })
  }
}
