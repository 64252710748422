import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  // Encrypt Data(n)
  encryptData(data) {
    try {
      if (data) {
        return CryptoJS.AES.encrypt(JSON.stringify(data), '1234509876encryptdecrypt1234567890').toString();
      }
    } catch (e) {
    }
  }

  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, '1234509876encryptdecrypt1234567890');
      if (bytes.toString()) {
        if (CryptoJS.enc.Utf8) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
      }
      if (data) {
        return data;
      }
    } catch (e) {
    }
  }

  ForgotPassword(data) {
    return this.http.post(`${environment.api_url}/AccountAPI/UserForGotPasswordEmail?email=${data.Email}`, null);
  }
  GetPonumber(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/GetPonumber?ProjectID=${data.ProjectID}`, null);
  }
  ReportToInquiry(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/ReportToInquiry`, data);
  }
  AllUserList(data) {
    return this.http.post(`${environment.api_url}/UserOperation/AllUserListNew`, data);
  }
  GetPermissionList(data) {
    return this.http.post(`${environment.api_url}/Permission/GetPermissionList`, data);
  }
  AllContactList(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/AllContactListnew`, data);
  }
  AllContactListForInquiry(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/AllContactListForInquiry`, data);
  }
  GetOrderEntryTrackingSheet_new(data) {
    return this.http.post(`${environment.api_url}/LogisticOperation/GetOrderEntryTrackingSheet_new`, data);
  }
  AllCompanyList(data) {
    return this.http.post(`${environment.api_url}/CompanyOperation/AllCompanyListnew`, data);
  }
  fillDesignations(data) {
    return this.http.post(`${environment.api_url}/UserOperation/fillDesignations`, data);
  }
  FillDesignationForContact(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/FillDesignation`, data);
  }
  //business card
  AllBusinessCardList(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/AllBusinessCardList`, data);
  }
  GetBusinessCardDetailById(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/GetBusinessCardDetailById`, data);
  }
  DeleteBusinessCard(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/DeleteBusinessCard`, data);
  }

  Fillnationality(data) {
    return this.http.post(`${environment.api_url}/UserOperation/Fillnationality`, data);
  }
  FillRole(data) {
    return this.http.post(`${environment.api_url}/UserOperation/FillRole`, data);
  }
  ChangeUserStatus(data) {
    return this.http.post(`${environment.api_url}/UserOperation/ChangeUserStatus`, data);
  }
  ChangeUserStatusdelete(data) {
    return this.http.post(`${environment.api_url}/UserOperation/ChangeUserStatus`, data);
  }
  FillReligion(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/FillReligion`, data);
  }
  Logout(data) {
    return this.http.post(`${environment.api_url}/AccountApi/Logout`, data);
  }
  FillTerritoryreport(data) {
    return this.http.get(`${environment.api_url}/InquiryAPI/FillTerritory`, data);
  }
  GetStatusOfDelivery(data) {
    return this.http.get(`${environment.api_url}/LogisticOperation/GetStatusOfDelivery`, data);
  }
  ProjectVerticalList(data) {
    return this.http.get(`${environment.api_url}/InquiryAPI/ProjectVerticalList`, data);
  }
  AddStagesList(data) {
    return this.http.get(`${environment.api_url}/InquiryAPI/AddStagesList`, data);
  }
  GetOrderTypeList(data) {
    return this.http.get(`${environment.api_url}/InquiryAPI/GetOrderTypeList`, data);
  }

  //amit code
  FilQuotation() {
    return this.http.post(`${environment.api_url}/ProjectOperation/FillQuotation`, null);
  }
  fillSalesEngineer() {
    return this.http.post(`${environment.api_url}/ProjectOperation/fillSalesEngineer`, null);
  }
  FillDesignConsultant(data) {
    return this.http.post(`${environment.api_url}/ProjectOperation/FillDesignConsultant`, data);
  }
  FillDevloper(data) {
    return this.http.post(`${environment.api_url}/ProjectOperation/FillDevloper`, data);
  }

  AddProject(data) {
    return this.http.post(`${environment.api_url}/ProjectOperation/AddProject`, data);
  }
  
  UpdateProject(data) {
    return this.http.post(`${environment.api_url}/ProjectOperation/UpdateProject`, data);
  }

  AddInquiry(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/AddInquiry`, data);
  }
  ProjectList(data) {
    return this.http.post(`${environment.api_url}/ProjectOperation/ProjectList`, data);
  }

  FillProjectName(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/FillProjectName`, data);
  }

  ProjectViewByID(data) {
    return this.http.get(`${environment.api_url}/ProjectOperation/ProjectViewByID?ProjectID=${data.ProjectID}`, data);
  }
  DeleteProjectByID(data) {
    return this.http.post(`${environment.api_url}/ProjectOperation/DeleteProjectByID?ProjectID=${data.ProjectID}`, null);
  }
  ProjectToInquiry(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/ProjectToInquiry?ProjectID=${data.ProjectID}`, null);
  }

  StagesList(data) {
    return this.http.get(`${environment.api_url}/InquiryAPI/StagesList`, data);
  }
  filterStagesList(data) {
    return this.http.get(`${environment.api_url}/InquiryAPI/filterStagesList`, data);
  }
  UpdateInquiryByID(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/UpdateInquiryByID`, data);
  }

  ProductListView(data) {
    return this.http.post(`${environment.api_url}/ProjectOperation/ProductListView`, data);
  }
  UpdateUserById(data) {
    return this.http.post(`${environment.api_url}/UserOperation/UpdateUserById`, data);
  }

  InquiryViewList(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/InquiryViewList`, data);
  }
  ContactsView(data) {
    return this.http.post(`${environment.api_url}/ProjectOperation/ContactsView`, data);
  }
  FootPrintListView(data) {
    return this.http.post(`${environment.api_url}/ProjectOperation/FootPrintListView`, data);
  }
  InquiryFootPrintListView(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/InquiryFootPrintListView`, data);
  }

  AddFootPrint(data) {
    return this.http.post(`${environment.api_url}/ProjectOperation/AddFootPrint`, data);
  }
  AddInquiryFootPrint(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/AddInquiryFootPrint`, data);
  }
  //amit

  //amit product api's 
  DeleteProductById(data) {
    return this.http.delete(`${environment.api_url}/InquiryAPI/DeleteProductById?ProductId=${data.ProductId}`, data);
  }
  ProductViewList(data) {
    return this.http.get(`${environment.api_url}/InquiryAPI/ProductViewList?InquiryID=${data.InquiryID}`, data);
  }
  ApplicationList(data) {
    return this.http.get(`${environment.api_url}/ProductOperation/ApplicationList`, data);
  }
  addProduct(data) {
    return this.http.post(`${environment.api_url}/ProductOperation/addProduct`, data);
  }
  DeliveryTermList1(data) {
    return this.http.get(`${environment.api_url}/InquiryAPI/DeliveryTermList`, data);
  }
  ProductSerieslist(data) {
    return this.http.get(`${environment.api_url}/ProductOperation/ProductSerieslist`, data);
  }
  fillProductName(data) {
    return this.http.get(`${environment.api_url}/ProductOperation/fillProductName?ProductName=a`, data);
  }
  ProductData(data) {
    return this.http.get(`${environment.api_url}/InquiryAPI/ProjectLists?InquiryId=${data.InquiryID}`, data);
  }
  ProductInquiryList(data) {
    return this.http.get(`${environment.api_url}/ProductOperation/ProductInquiryList?InquiryID=${data.InquiryID}`, data);
  }

  FillInquiryID(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/FillInquiryID`, data);
  }
  LogisticsGoNumForSearch(data) {
    return this.http.get(`${environment.api_url}/LogisticOperation/LogisticsGoNumForSearch?GoNumber=a`, data);
  }
  FinanceList(data) {
    return this.http.post(`${environment.api_url}/Finance/FinanceList`, data);
  }
  DeleteFinance(data) {
    return this.http.post(`${environment.api_url}/Finance/DeleteFinance?id=${data.id}`, data);
  }
  FinanceInquiriesById(data) {
    return this.http.get(`${environment.api_url}/Finance/FinanceInquiriesById?ProjectId=${data.ProjectId}`, data);
  }
  FinanceCusOrderName(data) {
    return this.http.get(`${environment.api_url}/Finance/FinanceCusOrderName?InquiryId=${data.InquiryId}`, data);
  }
  AddFinanceStagesList(data) {
    return this.http.get(`${environment.api_url}/Finance/AddFinanceStagesList`, data);
  }
  AddFinance(data) {
    return this.http.post(`${environment.api_url}/Finance/AddFinance`, data);
  }
  FinanceStagesList(data) {
    return this.http.get(`${environment.api_url}/Finance/FinanceStagesList`, data);
  }
  UpdateFinance(data) {
    return this.http.post(`${environment.api_url}/Finance/UpdateFinance`, data);
  }
  ImportFinanceList(data) {
    return this.http.post(`${environment.api_url}/Finance/ImportFinanceList`, data);
  }

  //amit SE-Port API
  VerticalList(data) {
    return this.http.get(`${environment.api_url}/SalesStatisticsAPI/VerticalList`, data);
  }
  fiscallist(data) {
    return this.http.get(`${environment.api_url}/SalesStatisticsAPI/fiscallist`, data);
  }
  Forecast(data) {
    return this.http.post(`${environment.api_url}/SalesStatisticsAPI/Forecast`, data);
  }
  SeHistoricalData(data) {
    return this.http.post(`${environment.api_url}/SalesStatisticsAPI/SeHistoricalData`, data);
  }
  OrderOverView(data) {
    return this.http.post(`${environment.api_url}/SalesStatisticsAPI/OrderOverViewFilter`, data);
  }
  LPOSalesengineerDetail(data) {
    return this.http.post(`${environment.api_url}/SalesStatisticsAPI/LPOSalesengineerDetail`, data);
  }
  LPOSalesengineerDetailWithFilter(data) {
    return this.http.post(`${environment.api_url}/SalesStatisticsAPI/LPOSalesengineerDetailWithFilter`, data);
  }
  LPOSalescountList(data) {
    return this.http.get(`${environment.api_url}/SalesStatisticsAPI/LPOSalescountList?Month=${data.Month}&salesengineerID=${data.salesengineerID}&year=${data.year}`, data);
  }
  PSheet(data) {
    return this.http.post(`${environment.api_url}/SalesStatisticsAPI/PsheetNew`, data);
  }
  ProspectReport(data) {
    return this.http.post(`${environment.api_url}/SalesStatisticsAPI/ProspectReport`, data);
  }
  ProspectReportSetHideColumnVal(data) {
    return this.http.post(`${environment.api_url}/SalesStatisticsAPI/ProspectReportSetHideColumnVal`, data);
  }
  JihPen(data) {
    return this.http.post(`${environment.api_url}/SalesStatisticsAPI/JihPen`, data);
  }

  FillProjectName123() {
    return this.http.get(`${environment.api_url}/InquiryAPI/FillProjectName`, null);
  }
  FillLanguage(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/FillLanguage`, data);
  }
  FillProreservation(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/FillProreservation`, data);
  }
  FillTerritory(data) {
    return this.http.post(`${environment.api_url}/UserOperation/FillTerritory`, data);
  }
  CountryList(data) {
    return this.http.post(`${environment.api_url}/UserOperation/CountryList`, data);
  }
  RoleList(data) {
    return this.http.post(`${environment.api_url}/AccountApi/RoleList`, data);
  }
  RoleId(data) {
    return this.http.post(`${environment.api_url}/AccountApi/RoleId?UserId=${data.UserId}`, null);
  }
  GetRoleIds(data) {
    return this.http.get(`${environment.api_url}/AccountApi/GetRoleIds?RoleID=${data.RoleID}`, data);
  }
  CheckPermissionList(data) {
    return this.http.post(`${environment.api_url}/AccountApi/CheckPermissionList?RoleId=${data.RoleId}`, data);
  }
  InsertPermission(data) {
    return this.http.post(`${environment.api_url}/Permission/InsertPermission`, data);
  }
  CityList(data) {
    return this.http.post(`${environment.api_url}/UserOperation/CityList`, data);
  }
  ReportList(data) {
    return this.http.post(`${environment.api_url}/SalesStatisticsAPI/ReportList_new`, data);
  } 
  StatusList1(data) {
    return this.http.get(`${environment.api_url}/SalesStatisticsAPI/StatusList1`, data);
  }
  filterStages(data) {
    return this.http.get(`${environment.api_url}/SalesStatisticsAPI/filterStagesList`, data);
  }
  AddUser(data) {
    return this.http.post(`${environment.api_url}/UserOperation/AddUser`, data);
  }
  GetQRCodeDetails(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/GetQRCodeDetails`, data);
  }
  CreateVCard(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/CreateVCard`, data);
  }
  AddPermisson(data) {
    return this.http.post(`${environment.api_url}/AccountApi/AddPermisson`, data);
  }
  AddContact(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/AddContact`, data);
  }

  ShowUserDetail(data) {
    return this.http.post(`${environment.api_url}/UserOperation/ShowUserDetailById?UserID=${data.UserID}`, null);
  }

  InquiryViewByID(data) {
    return this.http.get(`${environment.api_url}/InquiryAPI/InquiryViewByID?InquiryID=${data.InquiryID}`, data);
  }
  abcd() {
    return this.http.get(`https://rollmaster.itsabacus.net/api/rolls`);
  }
  hidecolumn(data) {
    return this.http.get(`${environment.api_url}/SalesStatisticsAPI/ProspectReportGetHideColumnVal?UserId=${data.UserId}`, data);
  }
  DeleteInquiryByID(data) {
    return this.http.get(`${environment.api_url}/InquiryAPI/DeleteInquiryByID?InquiryID=${data.InquiryID}`, data);
  }
  GetContactDetailById(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/GetContactDetailById?ContactID=${data.ContactID}`, null);
  }
  DeleteContact(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/DeleteContact?contactID=${data.contactID}`, null);
  }
  DeleteCompany(data) {
    return this.http.post(`${environment.api_url}/CompanyOperation/DeleteCompany?companyID=${data.companyID}`, null);
  }
  DeleteUser(id) {
    console.log(id)
    return this.http.post(`${environment.api_url}/UserOperation/DeleteUser?UserID=${id}`, null);
  }
  FillCompanyType(data) {
    return this.http.post(`${environment.api_url}/CompanyOperation/FillCompanyType`, data);
  }
  Verify(data) {
    return this.http.post(`${environment.api_url}/AccountApi/Verify`, data);
  }
  CompanyMerge(data) {
    return this.http.post(`${environment.api_url}/CompanyOperation/CompanyMerge`, data);
  }
  FillAccountManager(data) {
    return this.http.post(`${environment.api_url}/CompanyOperation/FillAccountManager`, data);
  }
  fillBusinessStream(data) {
    return this.http.post(`${environment.api_url}/CompanyOperation/fillBusinessStream`, data);
  }
  FillCategory(data) {
    return this.http.post(`${environment.api_url}/CompanyOperation/FillCategory`, data);
  }
  AddCompany(data) {
    return this.http.post(`${environment.api_url}/CompanyOperation/AddCompany`, data);
  }
  FilCompany(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/FilCompanyDesc`, data);
  }
  LogisticsInqIdForSearch(data) {
    return this.http.post(`${environment.api_url}/LogisticOperation/LogisticsInqIdForSearch`, data);
  }
  FilContactDesc(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/FilContactDesc`, data);
  }
  FillMepContractor(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/FillMepContractor`, data);
  }
  FillProject(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/FillProject`, data);
  }
  FillMainContractor(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/FillMainContractor`, data);
  }
  FillSuperVisionConsultantinquiry(data) {
    return this.http.post(`${environment.api_url}/ProjectOperation/FillSuperVisionConsultant`, data);
  }

  ImportContactList(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/ImportContactList`, data);
  }
  ImportCompanyList(data) {
    return this.http.post(`${environment.api_url}/CompanyOperation/ImportCompanyList`, data);
  }
  contactOwnerList(data) {
    return this.http.post(`${environment.api_url}/ContactOperation/contactOwnerList`, null);
  }
  ShowCompanyDetail(data) {
    return this.http.post(`${environment.api_url}/CompanyOperation/GetCompanyDetailById?CompanyID=${data.CompanyID}`, null);
  }

  //ashutosh

  //Ashutosh
  LogisticsList(data) {
    return this.http.post(`${environment.api_url}/LogisticOperation/LogisticsList_New`, data);
  }
  // FillProjectName(data) {
  //   return this.http.get(`${environment.api_url}/InquiryAPI/FillProjectName?ProjectName=a`, data);
  // }
  FillSalesEr(data) {
    return this.http.post(`${environment.api_url}/ProjectOperation/fillSalesEngineer`, data);
  }
  LogisticsListById(data) {
    return this.http.get(`${environment.api_url}/LogisticOperation/LogisticsListById?LogisticID=${data.LogisticId}`, data);
  }
  GetDeliveryOrderListByInquiryId(data) {
    return this.http.get(`${environment.api_url}/LogisticOperation/GetDeliveryOrderListByInquiryId?InquiryId=${data.InquiryId}&Status=${data.Status}`, data);
  }
  PoExistsInsameProject(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/PoExistsInsameProject?POnunberSave=${data.POnunberSave}&ProjectID=${data.ProjectID}`, null);
  }
  SaveGetPonumber(data) {
    return this.http.post(`${environment.api_url}/InquiryAPI/SaveGetPonumber?Dlink=${data.Dlink}&InquiryID=${data.InquiryID}&POnunberSave=${data.POnunberSave}`, null);
  }
  LogisticGoNumberList(data) {
    return this.http.post(`${environment.api_url}/LogisticOperation/LogisticGoNumberList`, data);
  }
  GetInquiryID(data) {
    return this.http.post(`${environment.api_url}/LogisticOperation/FillInquiryID`, data);
  }
  GonumberAdd(data) {
    return this.http.get(`${environment.api_url}/LogisticOperation/GonumberAdd?InquiryId=${data.InquiryId}`, data);
  }
  AddLogistics(data) {
    return this.http.post(`${environment.api_url}/LogisticOperation/AddLogistics`, data);
  }
  UpdateLogistics(data) {
    return this.http.post(`${environment.api_url}/LogisticOperation/UpdateLogistics`, data);
  }
  GetFactoryHead(data) {
    return this.http.get(`${environment.api_url}/LogisticOperation/GetFactoryHead`, data);
  }
  GetPaymentTypeList(data) {
    return this.http.get(`${environment.api_url}/LogisticOperation/GetPaymentTypeList`, data);
  }
  GetWarehouseList(data) {
    return this.http.get(`${environment.api_url}/LogisticOperation/GetWarehouseList`, data);
  }
  GetGOJobCardNumber(data) {
    return this.http.get(`${environment.api_url}/LogisticOperation/GetGOJobCardNumber?InquiryID=${data.InquiryId}`, data);
  }
  GetProductListForDelivery(data) {
    return this.http.get(`${environment.api_url}/LogisticOperation/GetProductListForDelivery?InquiryID=${data.InquiryId}`, data);
  }
  DeliveryTermList(data) {
    return this.http.get(`${environment.api_url}/LogisticOperation/DeliveryTermList`, data);
  }
  AddUpdateDeliveryOrder(data) {
    return this.http.post(`${environment.api_url}/LogisticOperation/AddUpdateDeliveryOrder`, data);
  }
  GetDeliveryOrderDetails(data) {
    return this.http.get(`${environment.api_url}/LogisticOperation/GetDeliveryOrderDetails?DeliveryId=${data.DeliveryId}`, data);
  }
  DeleteLogistics(data) {
    return this.http.post(`${environment.api_url}/LogisticOperation/DeleteLogistics?id=${data.Id}`, data);
  }
  ChangeDeliveryStatus(data) {
    return this.http.get(`${environment.api_url}/LogisticOperation/ChangeDeliveryStatus?DeliveryId=${data.DeliveryId}&Status=${data.Status}&UserId=${data.UserId}`, data);
  }

  testlazy(data, data1) {
    console.log(data, data1);
    return this.http.post(`${environment.api_url}/UserOperation/FillTerritory`, data, data1);
  }
}
