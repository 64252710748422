import { Routes } from "@angular/router";
import { FullComponent } from "./layouts/full/full.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./feature/dashboard/dashboard.component";
import { AuthGuard } from "./guards/auth.guard";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ScanComponent } from './scan/scan.component';

export const Approutes: Routes = [
  {
    path: "",
    component: FullComponent,
    children: [
      { path: "", redirectTo: "/login", pathMatch: "full" },

      {
        path: "profile",
        data: { preload: true },
        loadChildren: () => import('./BlueWeb/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: "company",
        data: { preload: true },
        loadChildren: () => import('./BlueWeb/company/company.module').then(m => m.CompanyModule)
      },
      {
        path: "inquiry",
        data: { preload: true },
        loadChildren: () => import('./BlueWeb/inquiry/inquiry.module').then(m => m.InquiryModule)
      },
      {
        path: "contact",
        data: { preload: true },
        loadChildren: () => import('./BlueWeb/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: "user",
        data: { preload: true },
        loadChildren: () => import('./BlueWeb/user/user.module').then(m => m.UserModule)
      },
      {
        path: "finance",
        data: { preload: true },
        loadChildren: () => import('./BlueWeb/finance/finance.module').then(m => m.FinanceModule)
      },
      {
        path: "Project",
        data: { preload: true },
        loadChildren: () => import('./BlueWeb/project/project.module').then(m => m.ProjectModule)
      },
      {
        path: "report",
        data: { preload: true },
        loadChildren: () => import('./BlueWeb/report/report.module').then(m => m.ReportModule)
      },
      {
        path: "se-portfolio",
        data: { preload: true },
        loadChildren: () => import('./BlueWeb/se-portfolio/se-portfolio.module').then(m => m.SePortfolioModule)
      },
      {
        path: "logistic",
        data: { preload: true },
        loadChildren: () => import('./BlueWeb/logistic/logistic.module').then(m => m.LogisticModule)
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        data: {
          title: "dashboard",
        },
      },
      {
        path: "",
        children: [
          {
            path: "",
            redirectTo: "/booking",
            pathMatch: "full",
          },
        ],
      },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "login",
    },
  },
  {
    path: "SelfQrCode",
    component: ScanComponent,
    data: {
      title: "SelfQrCode",
    },
  },
  {
    path: "Legal/Privacy-policy",
    component: PrivacyPolicyComponent,
    data: {
      title: "Legal/Privacy-policy",
    },
  },

  {
    path: "Legal/Privacy-policy",
    component: PrivacyPolicyComponent,
    data: {
      title: "Legal/Privacy-policy",
    },
  },
  //wildcard route
  {
    path: "**",
    component: LoginComponent,
    data: {
      title: "Login",
    },
  },
];
