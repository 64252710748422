<div class="position-relative  radial-gradient min-vh-100">
    <div class="position-relative z-index-5">
        <div class="row m-0 d-flex align-items-center bg-white">
            <div class="col-xl-7 col-xxl-8 body-bg d-none d-md-block bg-white">
                <!-- style="background-image: url('assets/images/login-page.jpg');" -->
                <a href="#" class="text-nowrap logo-img d-block px-4 py-9 w-100 mt-4">
                    <!-- <img src="assets/images/Blue_Crm_logo.jpg" width="260" alt=""> -->
                </a>
                <div class="d-none d-xl-flex align-items-center justify-content-center bg-white"
                    style="height: calc(100vh - 80px);">
                    <img src="assets/images/MEKAR-login v4.jpg" width="580" height="400"  alt="" loading="lazy" class="img-fluid">
                </div>
            </div>

            <div class="col-xl-5 col-xxl-4 bg-white">
                <div class="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
                    <div class="col-sm-8 col-md-6 col-xl-11">
                        <div class="text-center pb-2">
                            <h2 class="title text-dark mt-0 my-4 text-center">Welcome</h2>
                            <img src="assets/images/Blue_Crm_logo.jpg"
                                class="img-fluid text-center m-auto d-none d-md-block" alt="Logo" >
                        </div>

                        <form>


                            <div class="login-content">
                                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" action=""
                                    class="animated fadeIn login-form" *ngIf="loginBox">
                                    <img src="assets/images/Blue_Crm_logo.jpg" class="img-fluid d-md-none d-sm-block"
                                        alt="Logo" >

                                   

<div class="my-2">
                                    <i>Change does not roll in on the wheels of inevitability,
                                        but comes through continuous struggle
                                        <span class="mdi mdi-bell-outline mdi-18px text-info"></span>
                                    </i>
                                </div>
                                    <!-- <p class="error my-3 text-warning"><span class="mdi mdi-alert-outline"></span> You have entered a wrong email id or password</p> -->
                                    <div class="input-div one">
                                        <div class="i">
                                            <i class="mdi mdi-account"></i>
                                        </div>
                                        <label class="has-float-label">
                                            <span> Email ID<span class="red-asterisk">*</span></span>
                                            <input class="form-control bg-main" formControlName="email" type="email"
                                                placeholder="Email ID"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                maxlength="150" onkeypress="return event.charCode != 32" />
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                                <div *ngIf="f.email.errors.pattern">Email must be a valid email address
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="input-div pass">
                                        <div class="i">
                                            <i class="mdi mdi-lock"></i>
                                        </div>
                                        <label class="has-float-label input-box">
                                            <span>Password<span class="red-asterisk">*</span></span>
                                            <input class="form-control bg-main" formControlName="password"
                                                [type]="showPassword ? 'text' : 'password'" placeholder="********"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                                maxlength="20" onkeypress="return event.charCode != 32" />
                                            <div class='ShowPassword show-pass pt-2 pr-2 '>
                                                <i alt="show" class="fa fa-eye" (click)="showPassword = !showPassword"
                                                    [class.hide]="showPassword"></i>
                                                <i alt="hide" class="fa fa-eye-slash"
                                                    (click)="showPassword = !showPassword"
                                                    [class.hide]="!showPassword"></i>
                                            </div>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center my-3">
                                        <!-- <div class="round-checkbox">
                                <input type="checkbox" id="checkbox" checked/>
                                <label for="checkbox"></label>
                                <span class="pl-3">Reminder</span>
                            </div> -->
                                        <a class="text-dark font-weight-bold" href="javascript:void(0);"
                                            (click)="ForgotPassword();">Forgot Password?</a>
                                        <a class="text-dark font-weight-bold" href="javascript:void(0);"
                                            (click)="LoginWithOTP();">Login With OTP?</a>
                                    </div>
                                    <div class="d-flex align-items-center my-3">
                                        <input type="submit"
                                            class="btn login-btn btn btn-primary w-100 py-8 mb-4 rounded-2 rounded-pill"
                                            value="Login">
                                        <!-- <input type="submit" class="btn btn-light ml-3" value="Create Account" routerLink="/registration"> -->
                                    </div>

                                </form>
                                <form [formGroup]="forgetPassForm" class="animated fadeIn" *ngIf="forgotPasswordBox"
                                    (ngSubmit)="submitForgetPassForm()">
                                    <img src="assets/images/Blue_Crm_logo.png" class="img-fluid d-lg-none d-sm-block"
                                        alt="Logo" width="300">

                                    <h2 class="title text-dark mt-0 my-4 text-center">Forgot Password</h2>
                                    <!-- <h3 class="title text-dark mt-0">Forgot Password</h3> -->
<div class="my-2">
                                    <i>Enter your account email to recover your password.
                                        <span class="mdi mdi-bell-outline mdi-18px text-warning"></span>
                                    </i>
                                </div>
                                    <div class="input-div one">
                                        <div class="i">
                                            <i class="mdi mdi-account"></i>
                                        </div>


                                        <!-- <label class="has-float-label">
                                            <span> Email ID<span class="red-asterisk">*</span></span>
                                            <input class="form-control" formControlName="email" type="email"
                                                placeholder="Email ID"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                maxlength="150" onkeypress="return event.charCode != 32" />
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                                <div *ngIf="f.email.errors.pattern">Email must be a valid email address
                                                </div>
                                            </div>
                                        </label> -->

                                        <label class="has-float-label">
                                            <span>Email<span class="red-asterisk">*</span></span>
                                            <input class="form-control " formControlName="email_id" type="email"
                                                placeholder="Email"
                                                [ngClass]="{ 'is-invalid': submittedForm && p.email_id.errors }"
                                                maxlength="150" />
                                            <div *ngIf="submittedForm && p.email_id.errors" class="invalid-feedback">
                                                <div *ngIf="p.email_id.errors.required">Email is required</div>
                                                <div *ngIf="p.email_id.errors.email">Email must be a valid email address
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="d-flex align-items-center my-3">
                                        <input type="submit" class="btn" value="Recover Password">
                                        <input type="submit" class="btn btn-light ml-3" value="Go Back"
                                            (click)="login();">
                                    </div>
                                </form>

                                <form [formGroup]="LoginOTPForm" class="animated fadeIn" *ngIf="LoginWithOtpbox">
                                    <img src="assets/images/Blue_Crm_logo.png" class="img-fluid d-lg-none d-sm-block"
                                        alt="Logo">

                                    <!-- <h2 class="title text-dark mt-0">BLUEWEB CRM</h2> -->
                                    <h3 class="title text-dark mt-0 text-center">Login With OTP</h3>
<div class="my-2">
                                    <i>Enter your account email to receive a Email.
                                        <span class="mdi mdi-bell-outline mdi-18px text-warning"></span>
                                    </i>
                                </div>
                                    <div class="input-div one">
                                        <div class="i">
                                            <i class="mdi mdi-account"></i>
                                        </div>
                                        <label class="has-float-label">
                                            <span>Email<span class="red-asterisk">*</span>
                                            <input class="form-control border-0 bg-main" readonly formControlName="email_id_OTP"
                                                type="email" placeholder="Email" />


                                        </span>
                                        </label>

                                    </div>
                                    <div class="input-div one">
                                        <div class="i">
                                            <i class="fa fa-key"></i>
                                        </div>
                                        <label class="has-float-label">
                                            <span class="">OTP<span class="red-asterisk">*</span></span>

                                            <input class="form-control bg-main" formControlName="LOgin_OTP"
                                                [type]="showPassword ? 'text' : 'password'" placeholder="OTP" />
                                            <div class='ShowPassword show-pass-2 pt-2 pr-2'>
                                                <i alt="show" class="fa fa-eye" (click)="showPassword = !showPassword"
                                                    [class.hide]="showPassword"></i>
                                                <i alt="hide" class="fa fa-eye-slash"
                                                    (click)="showPassword = !showPassword"
                                                    [class.hide]="!showPassword"></i>
                                            </div>

                                        </label>

                                    </div>
                                    <div class="d-flex justify-content-between align-items-center my-3">


                                        <button *ngIf="time==false" class="text-dark font-weight-bold no-border"
                                            href="javascript:void(0);" (click)="LoginWithOTP();">Resend OTP?</button>
                                        <button *ngIf="time==true" class="text-dark font-weight-bold no-border"
                                            href="javascript:void(0);">You Will Get OTP In {{display}}</button>
                                    </div>

                                    <div class="d-flex align-items-center my-3">
                                        <!-- <a href="javascript:void(0);"  class="btn btn-info"></a> -->
                                        <a href="javascript:void(0);" (click)="Verify();" class="btn btn-info">Login</a>

                                        <input type="submit" class="btn btn-light ml-3" value="Go Back"
                                            (click)="login();">

                                    </div>
                                </form>
                                <a href="javascript:void(0);" routerLink="/Legal/Privacy-policy">Privacy-Policy</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div> 
    </div>

</div>
<!-- <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>  -->
<ngx-spinner>
    <div class="container">
        <div class="text-center d-flex align-items-center justify-content-center">
            <div class="">
                <div class="mb-2"><img src="assets/images/favicon 2.png" class="bg-white p-0 rounded-circle img-rotate"
                        alt="" width="100px"></div>
                <!-- <div class="preloader-bar"></div> -->
            </div>
        </div>
    </div>
</ngx-spinner>
{{display}}