import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if (window) {
   
    window.console.log= function () {};
    window.console.warn= function () {};
    window.console.error= function () {}
  

}
}else
{

}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

  function getPageFromURL() {
    const path = window.location.pathname;
    const page = path.split('/').pop().split('.')[0]; // Extract the page name without extension
    return page || 'home'; // Default to 'home' if no page is specified
}



