<nav class="sidebar-nav">

    <ul id="sidebarnav">
        <li [class.active]="showMenu === 'f1'" class="mt-3">
            <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" (click)="addExpandClass('f1')"
                aria-expanded="false">
                <!-- <img src="assets/images/users/8.jpg" alt="" class="img-circle" width="40"> -->
                <img *ngIf="defaultimg==false" src="{{profileImgUrl}}" alt="" class="img-circle" width="40" height="40">
                <img *ngIf="defaultimg==true" src="assets/images/person.png" alt="" class="img-circle" width="40"
                    height="40">
                <span class="hide-menu">{{Profiledata?.UserName}}</span></a>
            <ul aria-expanded="false" class="collapse" [class.in]="showMenu === 'f1'">
                <li><a href="javascript:void(0);" routerLink="/profile" class="text-white"><i
                            class="mdi mdi-account-circle pt-1"></i> My
                        Profile</a>
                </li>
                <!-- <li><a href="javascript:void(0);" routerLink="/companyprofilelist"><i class="mdi mdi mdi-store"></i> Company Profile</a></li> -->
                <li><a href="javascript:void(0);" (click)="logoutFunction()" class="text-white"><i
                            class="mdi mdi-logout pt-1"></i>
                        Logout</a>
                </li>
            </ul>
        </li>

        <li>
            <a *ngIf="Company =='1'" href="javascript:void(0)" routerLink="/company" routerLinkActive="active">
                <span class="mdi mdi-domain pt-1"></span>
                <span class="hide-menu">Company</span></a>
        </li>

        <li>
            <!-- routerLink="/contacts" -->
            <a *ngIf="Contact =='1' || BusinessCard =='1'" href="javascript:void(0)" routerLinkActive="active" routerLink="/contact/contacts">
                <span class="mdi mdi-phone-classic pt-1"></span>
                <span class="hide-menu">Contact</span></a>
        </li>

        <li>
            <!-- routerLink="/user" -->
            <a *ngIf="User =='1'" href="javascript:void(0)" routerLinkActive="active" routerLink="/user">
                <span class="mdi mdi-account-box pt-1"></span>
                <span class="hide-menu">User</span></a>
        </li>

        <li>
            <a *ngIf="Project =='1'" href="javascript:void(0)" routerLinkActive="active"
                routerLink="/Project/Projectlist">
                <span class="mdi mdi-equal-box pt-1"></span>
                <span class="hide-menu">Project</span></a>
        </li>

        <li>
            <a *ngIf="Inquiry =='1'" href="javascript:void(0)" routerLinkActive="active" (click)="inqlist()"
                routerLink="inquiry/inquirylist">
                <span class="mdi mdi-file-find pt-1"></span>
                <span class="hide-menu">Inquiry</span></a>
        </li>

        <li>
            <a *ngIf="FinanceAndCommercial =='1'" href="javascript:void(0)" routerLinkActive="active" routerLink="finance/financelist">
                <span class="mdi mdi-chart-bar pt-1"></span>
                <span class="hide-menu">Finance & Com.</span></a>
        </li>

        <li>
            <a *ngIf="Logistic =='1'" href="javascript:void(0)" routerLinkActive="active"
                routerLink="logistic/logisticlist">
                <span class="mdi mdi-bus pt-1"></span>
                <span class="hide-menu"> Logistics</span></a>
        </li>

        <li>
            <a *ngIf="SePortfolio =='1'" href="javascript:void(0)" routerLinkActive="active" (click)="GoToP()" routerLink="/se-portfolio/psheet-list">
                <span class="mdi mdi-arrange-bring-forward pt-1"></span>
                <span class="hide-menu">SE Portfolio</span></a>
        </li>
        <li>
            <a *ngIf="Report =='1'" href="javascript:void(0)" routerLinkActive="active" routerLink="/report">
                <span class="mdi mdi-file-document pt-1"></span>
                <span class="hide-menu">Report</span></a>
        </li>
    </ul>
</nav>
<ngx-spinner>
    <div class="container">
        <div class="text-center d-flex align-items-center justify-content-center">
            <div class="">
                <div class="mb-2"><img src="assets/images/favicon 2.png" class="bg-white p-0 rounded-circle img-rotate"
                        alt="" width="100px"></div>
            </div>
        </div>
    </div>
</ngx-spinner>