import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, 
    private toastrService: ToastrService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        console.log(event)
        if (event instanceof HttpResponse && event.status === 200 && event.body.Message === "Authorization has been denied for this request.") {
            console.log("hhhhhhhh")
            localStorage.removeItem("token");
            this.toastrService.error("Session expired! Please login again.");
           // this.spinner.hide();
            this.router.navigate(['/login']);
        }
      })
    );
  }
}
