<textarea [value]="qrdata | json" rows="10" cols="50" id="myTextarea" hidden></textarea>
<div class="col-xl-10 col-lg-10 col-md-10 mx-auto mt-3">
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center py-2">
            <h5 class="mb-0">QR Code Information</h5>
            <div class="d-flex align-items-center gap-2">
            <button title="Copy Detail" type="button" class="btn  btn-light-info bg-info border-info mr-3" (click)="copyAccessToClipboard()"><i class="fa fa-copy"></i></button>
            <button title="Download" type="button" class="btn btn-sm btn-light-info" (click)="CreateVCard()"><i class="fa fa-download"></i></button>
        </div>
        </div>
        <div class="card-body new-detail-page">
            <form>
                <div class="form-row mt-3 odd">
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <div class="row">
                            <label class="col-sm-6 col col-form-label text-left pl-3">User Name :</label>
                            <div class="col-sm-6 px-2 col">
                                <p class="pt-2 mb-0 font-weight-bold">{{qrdata?.UserName}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <div class="row">
                            <label class="col-sm-6 col col-form-label text-left pl-3">Company :</label>
                            <div class="col-sm-6 px-2 col">
                                <p class="pt-2 mb-0 font-weight-bold">{{qrdata?.Company}}</p>
                            </div>
                        </div>
                    </div>   
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <div class="row">
                            <label class="col-sm-6 col col-form-label text-left pl-3">Designation :</label>
                            <div class="col-sm-6 px-2 col">
                                <p class="pt-2 mb-0 font-weight-bold">{{qrdata?.Designation}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-row even ">
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <div class="row">
                            <label class="col-sm-6 col col-form-label text-left pl-3">Email :</label>
                            <div class="col-sm-6 px-2 col">
                                <p class="pt-2 mb-0 font-weight-bold">{{qrdata?.Email}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <div class="row">
                            <label class="col-sm-6 col col-form-label text-left pl-3">CompanyAddress :</label>
                            <div class="col-sm-6 px-2 col">
                                <p class="pt-2 mb-0 font-weight-bold">{{qrdata?.CompanyAddress}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <div class="row">
                            <label class="col-sm-6 col col-form-label text-left pl-3">OfficeMobIle :</label>
                            <div class="col-sm-6 px-2 col">
                                <p class="pt-2 mb-0 font-weight-bold">{{qrdata?.OfficeMobIle}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row odd ">

                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <div class="row">
                            <label class="col-sm-6 col col-form-label text-left pl-3">OfficeTel :</label>
                            <div class="col-sm-6 px-2 col">
                                <p class="pt-2 mb-0 font-weight-bold">{{qrdata?.OfficeTel}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <div class="row">
                            <label class="col-sm-6 col col-form-label text-left pl-3">Website :</label>
                            <div class="col-sm-6 px-2 col">
                                <p class="pt-2 mb-0 font-weight-bold">{{qrdata?.Website}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <div class="row">
                            <label class="col-sm-6 col col-form-label text-left pl-3">OfficeFax :</label>
                            <div class="col-sm-6 px-2 col">
                                <p class="pt-2 mb-0 font-weight-bold">{{qrdata?.OfficeFax}}</p>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div class="form-row even ">

                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <div class="row">
                            <label class="col-sm-6 col col-form-label text-left pl-3">CompanyAddress :</label>
                            <div class="col-sm-6 px-2 col">
                                <p class="pt-2 mb-0 font-weight-bold">{{qrdata?.CompanyAddress}}</p>
                            </div>
                        </div>
                    </div>
                    
                   
                </div>
               

              

               
            </form>
        </div>
    </div>
</div>



