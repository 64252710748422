import { LoginComponent } from './login/login.component';
import { AppComponent } from './app.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from "ngx-spinner";
import { Approutes } from './app-routing.module';
import { FullComponent } from './layouts/full/full.component';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SpinnerComponent } from './shared/spinner.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DashboardComponent } from './feature/dashboard/dashboard.component';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CustomPreloadingService } from './services/custom-preloading.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TreeviewModule } from 'ngx-treeview';
import { ScanComponent } from './scan/scan.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,
        FullComponent,
        NavigationComponent,
        SidebarComponent,
        LoginComponent,
        DashboardComponent,
        PrivacyPolicyComponent,
        ScanComponent,
    ],
    imports: [
        SharedModule,
        TreeviewModule.forRoot(),
        BrowserAnimationsModule,
        BrowserModule,
        ImageCropperModule,
        NgxSpinnerModule,
        RouterModule.forRoot(Approutes,
            {
                relativeLinkResolution: 'legacy',
                preloadingStrategy: CustomPreloadingService
            }
        ),
        PerfectScrollbarModule,
        ChartsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ],
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true } 
      ], 
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor() {
        console.log('App Module Loaded');
    }
}
