import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/userService/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  id: string;
  profileImgUrl: any;
  Profiledata: any;
  roleid: any;
  com: any;
 
  RolesPermissions: any;
  
  defaultimg: boolean = false;
  permission: any;
  Company: any;
  Contact: any;
  FinanceAndCommercial: any;
  Inquiry: any;
  Logistic: any;
  Project: any;
  Report: any;
  SePortfolio: any;
  User: any;
  BusinessCard: any;
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private UserService: UserService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }
  // End open close
  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    this.id = localStorage.getItem("UserId");
    this.id = this.UserService.decryptData(this.id);
   
   
    this.ShowUserDetail();
    
    var permi = JSON.parse(localStorage.getItem('ModulePermission'));
    this.permission = this.UserService.decryptData(permi);
    let data = this.permission;
    this.Company = data['Company']
    this.Contact = data['Contact']
    this.FinanceAndCommercial = data['FinanceAndCommercial']
    this.Inquiry = data['Inquiry']
    this.Logistic = data['Logistic']
    this.Project = data['Project']
    this.Report = data['Report']
    this.SePortfolio = data['SePortfolio']
    this.User = data['User']
    this.BusinessCard = data['BusinessCard']
    

  }

  ShowUserDetail() {
    var data = {
      "UserID": this.id,
      // "Mode": "Edit"
    }
    // this.spinner.show();
    this.UserService.ShowUserDetail(data).subscribe(res => {
      if (res != null) {
        this.Profiledata = res;
        if (this.Profiledata['ImagePath'] == 'Images/UserImages/boy-512.png') {
          this.defaultimg = true;
          console.log("aaaa");
        }
        else {
          this.profileImgUrl = res['ImagePath'];
        }

      }
      else {
        this.profileImgUrl = "";
      }
    })
  }
  logoutFunction() {
    this.spinner.show();
    var data = {
    }

    this.UserService.Logout(data).subscribe(res => {
      if (res['result'] == 'success') {
        this.spinner.hide();
        this.toastrService.success(res['Msg'])
        localStorage.clear();
        this.router.navigate(['/login']);
      } else {
        this.spinner.hide();
        this.toastrService.error(res['Message'])
      }
    })
  }
  // RoleId() {
  //   var data = {
  //     "UserId": this.id
  //   }
  //   this.UserService.RoleId(data).subscribe(res => {
  //     if (res != null) {
  //       this.roleid = res;
  //       this.GetRoleIds(this.roleid)
  //       // this.CheckPermissionList(this.roleid)
  //     } else {
  //       console.log("abhi123aaaaaaaa", typeof (res));
  //     }
  //   })
  // }
  // GetRoleIds(id) {
  //   var data = {
  //     "RoleID": id
  //   }
  //   this.UserService.GetRoleIds(data).subscribe(res => {
  //     if (res != null) {
  //       this.Activity = res[0]['Activity'].replace(/\s/g, "");
  //       this.BulkEmail = res[0]['BulkEmail'].replace(/\s/g, "");
  //       this.Company = res[0]['Company'].replace(/\s/g, "");
  //       this.Contact = res[0]['Contact'].replace(/\s/g, "");
  //       this.Dasboard = res[0]['Dasboard'].replace(/\s/g, "");
  //       this.EmailAlerts = res[0]['EmailAlerts'].replace(/\s/g, "");
  //       this.Export = res[0]['Export'].replace(/\s/g, "");
  //       this.FinanceCommercial = res[0]['FinanceCommercial'].replace(/\s/g, "");
  //       this.Iniqury = res[0]['Iniqury'].replace(/\s/g, "");
  //       this.Logistics = res[0]['Logistics'].replace(/\s/g, "");
  //       this.Project = res[0]['Project'].replace(/\s/g, "");
  //       this.Report = res[0]['Report'].replace(/\s/g, "");
  //       this.RolesPermissions = res[0]['RolesPermissions'].replace(/\s/g, "");
  //       this.SEPortfolio = res[0]['SEPortfolio'].replace(/\s/g, "");
  //       this.UserManagement = res[0]['UserManagement'].replace(/\s/g, "");
  //       // localStorage.setItem('Permission', JSON.stringify(res[0]));
  //       var encryptrole = this.UserService.encryptData(JSON.stringify(res[0]));
  //       localStorage.setItem('Permission', encryptrole);
  //       var RoleId = this.UserService.encryptData(JSON.stringify(res[0]['RoleId']));
  //       localStorage.setItem('RoleId', RoleId);
  //     } else {
  //       console.log("abhi123aaaaaaaa", typeof (res));
  //     }
  //   })
  // }
  inqlist() {
    localStorage.removeItem('useridInq');
    localStorage.removeItem('inqtype');
    //this.router.navigate(['/inquirylist']);
  }
  GoToP()
  {
    localStorage.removeItem('GoToPsheet');
  }
}
