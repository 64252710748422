//const BASE_URL = "http://43.229.227.26:81/BlueWebCRMAPI/";
const BASE_URL = "https://api.mekar-ipia.com/";
export const environment = {
  production: true,
  // api_url: "http://localhost:19705/API2",
  // api_url1: "http://localhost:19705/API",
  Vcard: BASE_URL + "VCard/",
  //  api_url: " http://43.229.227.26:81/BlueWebCRMAPI/API2",
  //  api_url1: " http://43.229.227.26:81/BlueWebCRMAPI/API",
  api_url: BASE_URL + "API2",
  sample_Excel: BASE_URL + "SampleExcelFile/",
  api_url3: BASE_URL,
};
