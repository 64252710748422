import { BnNgIdleService } from 'bn-ng-idle';
import { Router, ActivatedRoute } from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {fromEvent, Observable, Subscription} from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']


})
export class AppComponent {
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;
  constructor(private bnIdle: BnNgIdleService,
    private router: Router,
    private toastrService: ToastrService, ) {

  }

  // initiate it in your component OnInit
  ngOnInit(): void {
    this.bnIdle.startWatching(3600).subscribe((res) => {
      if (res) {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.toastrService.error("Your Session expired")
      }
    });
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      console.log('Online...');
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      console.log('Offline...');
      localStorage.clear();
        this.router.navigate(['/login']);
        this.toastrService.error("Connection lost! You are not connected to internet");
        window.location.reload();
    }));
  }
  ngOnDestroy(): void {
    /**
    * Unsubscribe all subscriptions to avoid memory leak
    */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
