import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TwoDigitDecimaNumberDirective } from '../BlueWeb/logistic/addlogistic/two-digit-decima-number.directive';
import { SignaturePadModule } from 'angular2-signaturepad';
import { TreeviewModule } from 'ngx-treeview';
import { DataTablesModule } from 'angular-datatables';
import { NiceSelectModule } from "ng-nice-select";
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ScheduleAllModule, RecurrenceEditorAllModule } from '@syncfusion/ej2-angular-schedule';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from '../guards/auth.guard';
import { AuthService } from '../services/authentication/auth.service';
import { TokenInterceptorService } from '../services/token-interceptor.service';
import { UserService } from '../services/userService/user.service';
import { RolePermissionService } from '../appService/role-permission.service';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BnNgIdleService } from 'bn-ng-idle';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { TopNavComponent } from '../shared/top-nav/top-nav.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({

  declarations: [
    TwoDigitDecimaNumberDirective,
    BreadcrumbComponent,
    TopNavComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],

  imports: [
    CommonModule,
    SignaturePadModule,
    TreeviewModule,
    SharedRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    MatAutocompleteModule,
    NgxDaterangepickerMd.forRoot(),
    MatPaginatorModule,
    AngularMultiSelectModule,
    MatFormFieldModule,
    NgSelectModule,
    DataTablesModule,
    NiceSelectModule,
    AngularEditorModule,
    NgxCaptchaModule,
    ScheduleAllModule,
    RecurrenceEditorAllModule,
    NgxDropzoneModule,
    ToastrModule.forRoot({
      timeOut: 2500,
      progressBar: true,
      progressAnimation: 'increasing',
      preventDuplicates: true,
      positionClass: 'toast-top-center',
    }),
  ],

  providers: [
    RolePermissionService, DatePipe, UserService, AuthService, AuthGuard, BnNgIdleService,

    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }

  ]
})

export class SharedModule {
  constructor() {
    console.log('Shared Module Loaded');
  }
}
