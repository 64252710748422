import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from '../services/userService/user.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.css'],
  //encapsulation:  ViewEncapsulation.Native
})
export class ScanComponent implements OnInit {
 
  qrCode: string;
  qrdata: any=[];
  mergeddata: string;
  Vcard: string;
 

  constructor(
    private UserService: UserService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.qrCode = params.get('Qr');
    });
    this.GetQRCodeDetails();
    this.Vcard = environment.Vcard;
  }
  GetQRCodeDetails() {
    this.spinner.show();
    var data = {
      "Qr": this.qrCode,
    }

    this.UserService.GetQRCodeDetails(data).subscribe((res) => {
      if (res['result'] == 'success') {
        this.spinner.hide();
        this.qrdata=res['Data'];
        console.log(this.qrdata)
      }
      else if (res['result'] == 'Failed') {
        this.toastrService.error(res['Msg']);
        this.spinner.hide();
      } else {
        this.toastrService.error(res['Msg']);
        this.spinner.hide();
      }
    })
  }
  copyAccessToClipboard() {
   this.mergeddata= this.getFormattedString();
    const textArea = document.createElement('textarea');

    textArea.value = this.mergeddata;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.toastrService.success('Detail Copied', 'Success');
  } 
  getStringFromObject(obj: any): string {
    let resultString = '';
    Object.keys(obj).forEach(key => {
      resultString += key + ': ' + obj[key] + '\n';
    });
    return resultString;
  }
  
  // This method will return the string
  getFormattedString(): string {
    return this.getStringFromObject(this.qrdata);
  }
  
  CreateVCard() {
    var data = {
      "Qr": this.qrCode,
    };
  
    this.UserService.CreateVCard(data).subscribe(
      (res) => {
        if (res['result'] == 'Success') {
          var cardname = res['Msg'];
          // Construct the download URL
          var downloadUrl = this.Vcard + '/' + cardname;
  
          // Navigate to the download URL
console.log(downloadUrl) 
          window.location.href = downloadUrl;
          console.log(window.location.href)
        } else {
          this.toastrService.error("Something Went Wrong");
        }
      },
      (error) => {
        this.toastrService.error("Something Went Wrong");
      }
    );
  }

}
