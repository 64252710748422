import { Injectable } from '@angular/core';
import { HttpClient, HttpInterceptor, } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(Data) {
    return this.http.post(`${environment.api_url}/AccountApi/Login`, Data);
  }
  SendOTP(data){
    return this.http.post(`${environment.api_url}/AccountApi/SendOTP?EmailPhone=${data.EmailPhone}`,null);
  }
  loggedIn()
  {
    return !!localStorage.getItem("AccessToken");
  }

  getToken()
  {
    return localStorage.getItem("AccessToken");
  }

  removeToken()
  {
    return localStorage.removeItem("AccessToken");
  }

}