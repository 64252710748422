<div class="row">
    <div class="col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex no-block">
                    <div class="m-r-20 align-self-center"><span class="lstick"></span><img
                            src="assets/images/icon/income.svg" alt="Income" width="50"></div>
                    <div class="align-self-center">
                        <h6 class="text-muted m-t-10 m-b-0">Total Income</h6>
                        <h2 class="m-t-0">$20000.00</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex no-block">
                    <div class="m-r-20 align-self-center"><span class="lstick"></span><img
                            src="assets/images/icon/member.svg" alt="Customer" width="50"></div>
                    <div class="align-self-center">
                        <h6 class="text-muted m-t-10 m-b-0">Total Customer</h6>
                        <h2 class="m-t-0">45000</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex no-block">
                    <div class="m-r-20 align-self-center"><span class="lstick"></span><img
                            src="assets/images/icon/booking.svg" alt="Booking" width="50"></div>
                    <div class="align-self-center">
                        <h6 class="text-muted m-t-10 m-b-0">Total Booking</h6>
                        <h2 class="m-t-0">12000</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex no-block">
                    <div class="m-r-20 align-self-center"><span class="lstick"></span><img
                            src="assets/images/icon/connection.svg" alt="connection" width="50"></div>
                    <div class="align-self-center">
                        <h6 class="text-muted m-t-10 m-b-0">Total Connections</h6>
                        <h2 class="m-t-0">20000</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div>
                        <h3 class="card-title m-b-5"><span class="lstick"></span>Monthly Sales Revenue </h3>
                    </div>
                    <div class="ml-auto">
                        <select class="custom-select b-0">
                            <option selected="">January 2020</option>
                            <option value="1">February 2020</option>
                            <option value="2">March 2020</option>
                            <option value="3">April 2020</option>
                        </select>
                    </div>
                </div>
                <div style="height:300px;" class="mt-4">
                    <canvas baseChart [datasets]="lineChartData1" [labels]="lineChartLabels1"
                        [options]="lineChartOptions1" [colors]="lineChartColors1" [legend]="lineChartLegend1"
                        [chartType]="lineChartType1"> </canvas>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div>
                        <h3 class="card-title m-b-5"><span class="lstick"></span>Fleet Status </h3>
                    </div>
                    <div class="ml-auto">
                        <select class="custom-select b-0">
                            <option selected="">January 2020</option>
                            <option value="1">February 2020</option>
                            <option value="2">March 2020</option>
                            <option value="3">April 2020</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <div style="height:300px;" class="mt-4">
                        <canvas baseChart height="100px" [data]="doughnutChartData" [labels]="doughnutChartLabels"
                            [options]="doughnutChartOptions" [legend]="doughnutChartLegend"
                            [chartType]="doughnutChartType"
                            [colors]="[{backgroundColor: ['rgb(236, 239, 241)', 'rgb(116, 90, 242)', 'rgb(38, 198, 218)', 'rgb(30, 136, 229)']}]">
                        </canvas>
                    </div>
                    <div class="w-100 px-5">
                        <h2 class="text-info">90% Fleet Efficiency</h2>
                        <ul class="list-unstyled fletStatus">
                            <li>
                                <p> Total Transportation </p>
                                <p> 80 </p>
                            </li>
                            <li>
                                <p> In Transit </p>
                                <p> 70 </p>
                            </li>
                            <li>
                                <p> In Pending </p>
                                <p> 10 </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div>
                        <h3 class="card-title m-b-5"><span class="lstick"></span>Average Delivery Time </h3>
                    </div>
                    <div class="ml-auto">
                        <select class="custom-select b-0">
                            <option selected="">January 2020</option>
                            <option value="1">February 2020</option>
                            <option value="2">March 2020</option>
                            <option value="3">April 2020</option>
                        </select>
                    </div>
                </div>
                <div class="mt-4">
                    <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                        [legend]="barChartLegend" [chartType]="barChartType" height="100%">
                    </canvas>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <div>
                    <h3 class="card-title m-b-5"><span class="lstick"></span>Average Weight Loaded </h3>
                </div>
                <div class="mt-4">
                       <ul class="list-unstyled boxStyle">
                           <li>
                               <div class="d-flex justify-content-start align-items-center">
                                   <div class="px-4">
                                       <span class="mdi mdi-weight mdi-36px"></span>
                                   </div>
                                   <div class="w-100">
                                       <h2 class="mb-0">2 Tons</h2>
                                       <p class="mb-0">Avg. Per Day Loading</p>
                                   </div>
                               </div>
                           </li>
                           <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="px-4">
                                    <span class="mdi mdi-weight mdi-36px"></span>
                                </div>
                                <div class="w-100">
                                    <h2 class="mb-0">12 Tons</h2>
                                    <p class="mb-0">Avg. Loading Weight</p>
                                </div>
                            </div>
                        </li>
                       </ul>
                </div>
            </div>
        </div>
    </div>


    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title"><span class="lstick"></span>Invoice Due</h4>
                <div class="table-responsive">
                    <table class="table vm no-th-brd pro-of-month mb-0 v-middle">
                        <thead>
                            <tr>
                                <th>Company</th>
                                <th>Amount</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <h6 class="mb-0">Amazon</h6>
                                </td>
                                <td><strong>$5000.00</strong></td>
                                <td><span class="badge badge-pill badge-warning">Pending</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <h6 class="mb-0">American Express</h6>
                                </td>
                                <td><strong>$4500.00</strong></td>
                                <td><span class="badge badge-pill badge-danger">Pending</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <h6 class="mb-0">Flipkart</h6>
                                </td>
                                <td><strong>$2000.00</strong></td>
                                <td><span class="badge badge-pill badge-warning">Pending</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title"><span class="lstick"></span>Equipment Maintenance</h4>
                <div class="table-responsive">
                    <table class="table vm no-th-brd pro-of-month mb-0 v-middle">
                        <thead>
                            <tr>
                                <th>Vehicle Type</th>
                                <th>Vehicle Number</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <h6 class="mb-0">Straight Trucks</h6>
                                </td>
                                <td><strong>US-A123658</strong></td>
                                <td><span class="badge badge-pill badge-warning">Pending</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <h6 class="mb-0">Flatbed</h6>
                                </td>
                                <td><strong>US-A146875</strong></td>
                                <td><span class="badge badge-pill badge-danger">Pending</span></td>
                            </tr>
                            <tr>
                                <td>
                                    <h6 class="mb-0">Refrigerated Trucks</h6>
                                </td>
                                <td><strong>US-A657984</strong></td>
                                <td><span class="badge badge-pill badge-warning">Pending</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>


    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title d-flex justify-content-between align-items-center">
                    <span><span class="lstick"></span>Invito News Week</span>
                    <a href="javascript:void(0);" class="btn btn-info btn-sm" (click)="editNews.show()"><span
                            class="mdi mdi-pencil"></span> Edit</a>
                </h4>
                <ul class="feeds">
                    <li class="feed-item d-flex py-2 align-items-center"><a href="javascript:void(0)"
                            class="align-items-center bg-light-info btn btn-circle d-flex justify-content-center"><i
                                class="mdi mdi-bell"></i></a>
                        <div class="ml-3 text-truncate">Promotion: Big Congratulations to Sally Brown for her promotion
                            and service of 20 years. Go Trucking!</div>
                        <div class="justify-content-end text-truncate ml-auto"><span class="text-muted font-12">Just
                                Now</span></div>
                    </li>
                    <li class="feed-item d-flex py-2 align-items-center"><a href="javascript:void(0)"
                            class="align-items-center bg-light-success btn btn-circle d-flex justify-content-center"><i
                                class="mdi mdi-bell"></i></a>
                        <div class="ml-3 text-truncate">Update: Big Congratulations to Sally Brown for her promotion
                            and service of 20 years. Go Trucking!</div>
                        <div class="justify-content-end text-truncate ml-auto"><span class="text-muted font-12">5 Hours
                                ago</span></div>
                    </li>
                    <li class="feed-item d-flex py-2 align-items-center"><a href="javascript:void(0)"
                            class="align-items-center bg-light-danger btn btn-circle d-flex justify-content-center"><i
                                class="mdi mdi-bell"></i></a>
                        <div class="ml-3 text-truncate">Promotion: Big Congratulations to Sally Brown for her promotion
                            and service of 20 years. Go Trucking!</div>
                        <div class="justify-content-end text-truncate ml-auto"><span class="text-muted font-12">Just
                                Now</span></div>
                    </li>
                    <li class="feed-item d-flex py-2 align-items-center"><a href="javascript:void(0)"
                            class="align-items-center bg-light-warning btn btn-circle d-flex justify-content-center"><i
                                class="mdi mdi-bell"></i></a>
                        <div class="ml-3 text-truncate">Update: Big Congratulations to Sally Brown for her promotion
                            and service of 20 years. Go Trucking!</div>
                        <div class="justify-content-end text-truncate ml-auto"><span class="text-muted font-12">5 Hours
                                ago</span></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>


<!--Terms & Conditions modal-->
<div class="modal fade" bsModal #editNews="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content animated zoomIn">
            <div class="modal-header">
                <h4 id="dialog-static-name" class="modal-title pull-left">Update News</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="editNews.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form action="">
                    <div class="form-group">
                        <label for="">Select</label>
                        <select name="" id="" class="custom-select">
                            <option value="">Promotion</option>
                            <option value="">Update</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="">Description</label>
                        <textarea name="" id="" rows="3" class="form-control"></textarea>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <a href="javascript:void(0);" class="btn btn-sm btn btn-info">Save Changes</a>
                <a href="javascript:void(0);" class="btn btn-sm btn btn-secondary ml-2"
                    (click)="editNews.hide()">Close</a>
            </div>
        </div>
    </div>
</div>