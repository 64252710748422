<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav mr-auto float-left">
    <li (click)="toggleSidebar.emit()" class="nav-item m-l-1 d-block"> <a
            class="nav-link sidebartoggler text-muted waves-effect waves-dark p-1" href="javascript:void(0)"><i
                class="mdi mdi-menu mdi-24px"></i></a> </li>
</ul>
<!-- ============================================================== -->
<!-- User profile and search -->
<!-- ============================================================== -->
<!-- <ul class="navbar-nav my-lg-0 ml-auto notification-icon">
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark notification-icon"
            href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            placement="bottom" ngbTooltip="Notifications">
            <i class="mdi mdi-bell-outline"></i>
            <div class="notify"><span class="heartbit"></span><span class="point"></span></div>
        </a>
        <div class="dropdown-menu dropdown-menu-right mailbox fadeIn" ngbDropdownMenu>
            <ul class="list-unstyled">
                <li>
                    <div class="drop-title">Notifications <a href="javascript:void(0);" class="float-right">Mark all as
                            read</a></div>
                </li>
                <li>
                    <div class="message-center ps">
                        <a href="javascript:void(0);">
                            <div class="round round-danger"><i class="ti-link"></i></div>
                            <div class="mail-contnet m-l-10">
                                <h5>Lorem Ipsum</h5>
                                <small class="mail-desc">Just see the my new admin!</small>
                                <small class="time">9:30AM</small>
                            </div>
                        </a>
                        <a href="javascript:void(0);">
                            <div class="round round-success"><i class="ti-calendar"></i></div>
                            <div class="mail-contnet m-l-10">
                                <h5>Lorem Ipsum</h5>
                                <small class="mail-desc">Just see the my new admin!</small>
                                <small class="time">9:30AM</small>
                            </div>
                        </a>
                        <a href="javascript:void(0);">
                            <div class="round round-info"><i class="ti-settings"></i></div>
                            <div class="mail-contnet m-l-10">
                                <h5>Lorem Ipsum</h5>
                                <small class="mail-desc">Just see the my new admin!</small>
                                <small class="time">9:30AM</small>
                            </div>
                        </a>
                        <a href="javascript:void(0);">
                            <div class="round round-primary"><i class="ti-user"></i></div>
                            <div class="mail-contnet m-l-10">
                                <h5>Lorem Ipsum</h5>
                                <small class="mail-desc">Just see the my new admin!</small>
                                <small class="time">9:30AM</small>
                            </div>
                        </a>
                    </div>
                </li>
                <li>
                    <a class="text-center d-block p-2" href="javascript:void(0);">
                        <small> Check All Notifications </small>
                    </a>
                </li>
            </ul>
        </div>
    </li>
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark notification-icon"
            href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            placement="bottom" ngbTooltip="Message">
            <i class="mdi mdi-message-text-outline"></i>
            <div class="notify"><span class="heartbit"></span><span class="point"></span></div>
        </a>
        <div class="dropdown-menu dropdown-menu-right mailbox fadeIn" ngbDropdownMenu>
            <ul class="list-unstyled">
                <li>
                    <div class="drop-title">Message <a href="javascript:void(0);" class="float-right">Mark all as
                            read</a></div>
                </li>
                <li>
                    <div class="message-center ps">
                        <a href="javascript:void(0);">
                            <div class="round round-danger"><i class="ti-link"></i></div>
                            <div class="mail-contnet m-l-10">
                                <h5>Lorem Ipsum</h5>
                                <small class="mail-desc">Just see the my new admin!</small>
                                <small class="time">9:30AM</small>
                            </div>
                        </a>
                        <a href="javascript:void(0);">
                            <div class="round round-success"><i class="ti-calendar"></i></div>
                            <div class="mail-contnet m-l-10">
                                <h5>Lorem Ipsum</h5>
                                <small class="mail-desc">Just see the my new admin!</small>
                                <small class="time">9:30AM</small>
                            </div>
                        </a>
                        <a href="javascript:void(0);">
                            <div class="round round-info"><i class="ti-settings"></i></div>
                            <div class="mail-contnet m-l-10">
                                <h5>Lorem Ipsum</h5>
                                <small class="mail-desc">Just see the my new admin!</small>
                                <small class="time">9:30AM</small>
                            </div>
                        </a>
                        <a href="javascript:void(0);">
                            <div class="round round-primary"><i class="ti-user"></i></div>
                            <div class="mail-contnet m-l-10">
                                <h5>Lorem Ipsum</h5>
                                <small class="mail-desc">Just see the my new admin!</small>
                                <small class="time">9:30AM</small>
                            </div>
                        </a>
                    </div>
                </li>
                <li>
                    <a class="text-center d-block p-2" href="javascript:void(0);">
                        <small> Check All Messages </small>
                    </a>
                </li>
            </ul>
        </div>
    </li>
</ul> -->
<!-- <span class="nav-item dropdown ml-3" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="dropdown-toggle waves-effect waves-dark btn btn-sm btn-info" href="javascript:void(0)"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="assets/images/users/8.jpg"
            alt="user" class="profile-pic" /> Jhon Doe</a>
    <div class="dropdown-menu" ngbDropdownMenu>
        <ul class="dropdown-user mb-0">
            <li><a href="javascript:void(0);" routerLink="/profile"><i class="mdi mdi-account"></i> My Profile</a></li>
            <li><a href="javascript:void(0);" routerLink="/companyprofilelist"><i class="mdi mdi mdi-store"></i> Company Profile</a></li>
            <li><a href="javascript:void(0);" routerLink="/login"><i class="mdi mdi-power"></i> Logout</a>
            </li>
        </ul>
    </div>
</span> -->