import { Injectable } from '@angular/core';
import { TreeviewItem } from 'ngx-treeview';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionService {
  getRole(): TreeviewItem[] {
    const dashboard = new TreeviewItem({
      text: 'Dashboard', value: 1, collapsed: false, children: [
        { text: 'Dashboard', value: 11 },
      ]
    });
    const company = new TreeviewItem({
      text: 'Company', value: 1, collapsed: false, children: [
        { text: 'Add Company', value: 11 },
        { text: 'Edit Company', value: 11 },
        { text: 'Delete Company', value: 11 },
        { text: 'Module Company', value: 11 },
      ]
    });
    const contact = new TreeviewItem({
      text: 'Contact', value: 1, collapsed: false, children: [
        { text: 'Add Contact', value: 11 },
        { text: 'Edit Contact', value: 11 },
        { text: 'Delete Contact', value: 11 },
        { text: 'Module Contact', value: 11 },
        // { text: 'Ground', value: 11 },
        // { text: 'Rails', value: 11 },
      ]
    });
    const user = new TreeviewItem({
      text: 'User', value: 1, collapsed: false, children: [
        { text: 'Add User', value: 11 },
        { text: 'Edit User', value: 11 },
        { text: 'Delete User', value: 11 },
        { text: 'Module User', value: 11 },
        { text: 'User Details', value: 11 },
        { text: 'User Permission', value: 11 },
        { text: 'User Attachment', value: 11 },
      ]
    });
    const productmanagment = new TreeviewItem({
      text: 'Product Managment', value: 1, collapsed: false, children: [
        { text: 'Add Product', value: 11 },
        { text: 'EQUIPMENT', value: 11 },
        { text: 'HVAC', value: 11 },
        { text: 'MEP', value: 11 },
        { text: 'SPARES', value: 11 },
        { text: 'SARVICES', value: 11 },
        { text: 'INSTALLATION', value: 11 },
      ]
    });
    const email = new TreeviewItem({
      text: 'Email', value: 1, collapsed: false, children: [
        { text: 'Email', value: 11 },
      ]
    });
    const project = new TreeviewItem({
      text: 'Project', value: 1, collapsed: false, children: [
        { text: 'Add Project', value: 11 },
        { text: 'Edit Project', value: 11 },
        { text: 'Delete Project', value: 11 },
        { text: 'Module Project', value: 11 },
      ]
    });
    const Inquiry = new TreeviewItem({
      text: 'Inquiry', value: 1, collapsed: true, children: [
        { text: 'Add Inquiry', value: 11 },
        { text: 'Edit Inquiry', value: 11 },
        { text: 'Delete Inquiry', value: 11 },
        { text: 'Module Inquiry', value: 11 },
      ]
    });
    const Task = new TreeviewItem({
      text: 'Task', value: 1, collapsed: true, children: [
        { text: 'Add Task', value: 11 },
        { text: 'Edit Task', value: 11 },
        { text: 'Delete Task', value: 11 },
        { text: 'Module Task', value: 11 },
      ]
    });
    const OrderReview = new TreeviewItem({
      text: 'Order Review', value: 1, collapsed: true, children: [
        { text: 'Add Order Review', value: 11 },
        { text: 'Edit Order Review', value: 11 },
        { text: 'Delete Order Review', value: 11 },
        { text: 'Module Order Review', value: 11 },
      ]
    });
    const Procurement = new TreeviewItem({
      text: 'Procurement', value: 1, collapsed: true, children: [
        { text: 'Add Procurement', value: 11 },
        { text: 'Edit Procurement', value: 11 },
        { text: 'Delete Procurement', value: 11 },
        { text: 'Module Procurement', value: 11 },
      ]
    });
    const Commercial = new TreeviewItem({
      text: 'Commercial', value: 1, collapsed: true, children: [
        { text: 'Add Commercial', value: 11 },
        { text: 'Edit Commercial', value: 11 },
        { text: 'Delete Commercial', value: 11 },
        { text: 'Add Shipment', value: 11 },
      ]
    });
    const Logistic = new TreeviewItem({
      text: 'Logistic', value: 1, collapsed: true, children: [
        { text: 'Logistic', value: 11 },
      ]
    });
    const Finance = new TreeviewItem({
      text: 'Finance', value: 1, collapsed: true, children: [
        { text: 'Finance', value: 11 },
      ]
    });
    const Report = new TreeviewItem({
      text: 'Report', value: 1, collapsed: true, children: [
        { text: 'Report', value: 11 },        
      ]
    });
    return [dashboard, company, contact, user, productmanagment, email, project, Inquiry, Task, OrderReview, Procurement, Commercial,
      Logistic, Finance, Report
    ];
  }
}